<template>
  <v-container>
    <v-row class="mt-8">
      <v-col align="center">
        <p class="text-h3">
          Contact Us
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" offset="1" lg="8" offset-lg="2">
        <v-card light>
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col align="center">
                You can reach us by phone at:
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col align="center" cols="6">
                <a href="tel:818-429-2924">(818) 429-2924</a><br>
                <span>Ramon Guzman</span><br>
                <span>Owner</span>
              </v-col>
              <v-col align="center" cols="6">
                <a href="tel:818-344-1323">(818) 344-1323</a>
                <p>Office</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" offset="1" lg="8" offset-lg="2">
        <v-card light>
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col align="center">
                Or email us directly using the form below. <br>
                We will be sure to deliver a timely response.
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col align="center" >
            <ContactForm></ContactForm>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ContactForm from "@/components/ContactForm";
export default {
  components: {ContactForm},
  name: "ContactView",
  metaInfo: {
    title: 'Contact Us',
  },
  data() {
    return {}
  },
};
</script>

<style scoped>
</style>
