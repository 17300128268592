<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-container>
      <v-row>
        <v-col cols="8" offset="2">
          <v-text-field
            v-model="formData.name"
            :rules="nameRules"
            label="Name"
            required
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" offset="2">
          <v-text-field
            v-model="formData.city"
            :rules="cityRules"
            label="City"
            required
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="formData.zip"
            :rules="zipRules"
            label="Zip Code"
            required
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" offset="2" md="4" offset-md="1">
          <v-text-field
            v-model="formData.phone"
            :rules="phoneRules"
            label="Phone Number"
            required
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="8" offset="2" md="6" offset-md="0">
          <span>Best time(s) to contact you</span>
          <v-row no-gutters>
            <v-col cols="8" offset="2" md="4" offset-md="0">
              <v-checkbox
                v-model="formData.times"
                multiple
                label="Morning"
                value="Morning"
              ></v-checkbox>
            </v-col>
            <v-col cols="8" offset="2" md="4" offset-md="0">
              <v-checkbox
                v-model="formData.times"
                multiple
                label="Afternoon"
                value="Afternoon"
              ></v-checkbox>
            </v-col>
            <v-col cols="8" offset="2" md="4" offset-md="0">
              <v-checkbox
                v-model="formData.times"
                multiple
                label="Evening"
                value="Evening"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" offset="1">
          <v-textarea
            v-model="formData.description"
            :rules="descriptionRules"
            label="Work Description"
            required
            outlined
          ></v-textarea>
        </v-col>
      </v-row>

      <p>{{ confirmationText }}</p>
      <v-btn @click="submit" color="primary">
        submit
      </v-btn>
    </v-container>
  </v-form>
</template>

<script>
import axios from 'axios'

export default {
  name: "ContactForm",
  data() {
    return {
      valid: false,
      formData: {},
      nameRules: [
        v => !!v || 'Name is required',
      ],
      cityRules: [
        v => !!v || 'City is required',
      ],
      zipRules: [
        v => !!v || 'Zip is required',
      ],
      phoneRules: [
        v => !!v || 'Phone number is required',
      ],
      descriptionRules: [
        v => !!v || 'Work description is required',
      ],
      confirmationText: ''
    }
  },
  methods: {
    submit() {
      this.confirmationText = ''
      if (this.$refs.form.validate()) {
        axios.post('/api/contact', this.formData)
          .then(() => {
            this.$refs.form.reset()
            this.confirmationText = 'Thank you! We will contact you soon.'
          })
          .catch((error)  => {
            console.log(error)
            this.confirmationText = 'Sorry, there was an error sending the email.'
          });

      }
    },
  },
}
</script>

<style scoped>

</style>